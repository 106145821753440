import { Transaction, PublicKey } from "@solana/web3.js";
import { getTokenAccounts } from "../getBalance";
import { SapInstruction } from "../sapInstruction";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";

// use key
const ownerKey = "AydJEQNvH44P1VA8uSMqfF6MvqMVoeLoxMzt8Wtfrr3B";
const sourceKey = "FpC29f4hXQYQzFumDAMkFqGG4Hes75p5Qfor3Cy9nLNh";
const sypMintKeyMain = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
// const sypMintKeyDev = 'CPoQKy6pW76xhozbtYiMaCpejvThWjuwWTQpR2UCxrif';

export async function claimVesting(connection, wallet, amount) {
  // use account
  let walletAcc = wallet.publicKey;
  let mintAcc = new PublicKey(sypMintKeyMain);
  let userSypAcc = await getTokenAccounts(connection, walletAcc, mintAcc);
  if (userSypAcc) {
    let trxi = SapInstruction.createTransferInstruction(
      TOKEN_PROGRAM_ID,
      new PublicKey(sourceKey),
      userSypAcc,
      new PublicKey(ownerKey),
      [],
      amount * 10 ** 9
    );
    let transaction = new Transaction();
    transaction.add(trxi);
    let { blockhash } = await connection.getRecentBlockhash();
    transaction.recentBlockhash = blockhash;
    transaction.feePayer = walletAcc;
    let signed = "";
    try {
      signed = await wallet.signTransaction(transaction);
    } catch (e) {
      console.error("vest sign cancelled", e);
      return { code: -1, msg: "sign cancelled" };
    }
    return { code: 1, msg: "vest sign ok", signed, userSypAcc };
  } else {
    return { code: 0, msg: "user has no syp account" };
  }
}

export async function getVestingRewardVault(connection) {
  let sourceAcc = new PublicKey(sourceKey);
  let res = await connection.getTokenAccountBalance(sourceAcc, "confirmed");
  if (res.value) {
    return { code: 1, msg: "get vesting reward ok", data: res.value.uiAmount };
  } else {
    return { code: 0, msg: "get vesting reward fail", data: 0 };
  }
}
