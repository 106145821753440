import { PublicKey } from "@solana/web3.js";
import { getTokenAccountMaxAmount } from "../getBalance";

export const testCardKey = "48sbg8XfuLvdgqaDZbgwTXjGdHWu76Am3M2bdyL7mqfQ";
const testCardAcc = new PublicKey(testCardKey);

export async function getUserTestAuthority(connection, wallet) {
  // use account
  // let walletAcc = wallet.publicKey;
  // let test = { publicKey: new PublicKey('AAypeE9nbd6CLxcDBdeTeLsmze5wS37KnSy6TFcgUEGH') };
  // get max amount balance
  let res = await getTokenAccountMaxAmount(connection, wallet, testCardKey);
  if (res.code == 1) {
    if (res.data.amount > 0) {
      return { code: 1, msg: "user has test card" };
    } else {
      return { code: 0, msg: "user has no test card" };
    }
  } else {
    return res;
  }
}
