<template>
  <div class="view">
    <el-row :gutter="15">
      <el-col :span="12" :xs="24">
        <div class="text1">
          Total Investment: $ {{ totalInvestment.toFixed(3) }}
        </div>
        <div class="text1">
          Total PNL: <span class="amount">${{ totalPNL.toFixed(2) }}</span>
          <span :style="{ color: totalPNLPercent > 0 ? 'green' : 'red' }">
            {{ (totalPNLPercent * 100).toFixed(2) }} %
          </span>
        </div>
        <el-row :gutter="15">
          <el-col :span="6" :xs="24">
            <div
              class="btn-c"
              :class="userAuthority == 0 ? 'disabled' : ''"
              @click="openDialog2(0)"
            >
              Mint
            </div>
          </el-col>
          <el-col :span="6" :xs="24">
            <div
              class="btn"
              :class="userAuthority == 0 ? 'disabled' : ''"
              @click="openDialog2(1)"
            >
              Burn
            </div>
          </el-col>
          <el-col :span="6" :xs="24">
            <div class="btn" @click="actionTrade">Trade</div>
          </el-col>
          <el-col :span="6" :xs="24">
            <div class="btn" @click="actionVest">Vesting</div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="12" :xs="24">
        <div style="height: 280px" id="myChart" />
      </el-col>
    </el-row>
    <el-collapse class="box1">
      <el-collapse-item name="performance">
        <template slot="title">
          <h3 class="title3">SAP Performance</h3>
        </template>
        <el-table
          :data="performanceTable"
          max-height="500"
          @row-click="gotoSypool"
        >
          <el-table-column prop="name" label="Name" :min-width="140" />
          <el-table-column prop="shares" label="Shares" />
          <el-table-column prop="value" label="Value" />
          <el-table-column prop="APY" label="%" />
          <el-table-column prop="pnl" label="PNL" />
          <!-- <el-table-column prop="yearValuePercent" label="APY" /> -->
          <template slot="empty">
            <div class="noData">No available Data</div>
          </template>
        </el-table>
      </el-collapse-item>
      <el-collapse-item name="recent-transactions">
        <template slot="title">
          <h3 class="title3">Recent Transactions</h3>
        </template>
        <el-table :data="transactionHistoryList">
          <el-table-column label="Date" min-width="15%">
            <template slot-scope="scope">
              {{ scope.row.date.toLocaleString() }}
            </template>
          </el-table-column>
          <el-table-column
            label="Action"
            prop="actionStr"
            min-width="10%"
          ></el-table-column>
          <el-table-column
            label="SAP"
            prop="sapName"
            min-width="11%"
          ></el-table-column>
          <el-table-column label="Signature" min-width="44%">
            <template slot-scope="scope">
              <span>{{ scope.row.signature }}</span>
              <i
                class="el-icon-document-copy investor-copy"
                style="cursor: pointer"
                v-clipboard:success="onCopy"
                v-clipboard:copy="scope.row.signature"
              ></i>
              <i
                class="el-icon-share investor-copy"
                @click="viewOnSolscanTx(scope.row.signature)"
              ></i>
            </template>
          </el-table-column>
          <el-table-column label="Price" min-width="10%">
            <template slot-scope="scope">
              $ {{ scope.row.price.toFixed(2) }}
            </template>
          </el-table-column>
          <el-table-column label="Amount" min-width="10%">
            <template slot-scope="scope">
              {{ scope.row.amount.toFixed(2) }}
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>
    </el-collapse>

    <sap-dialog :small="small" :userAuthority="userAuthority" ref="sapDialog" />
    <vest-dialog ref="vestDialog" />
    <trade-dialog :small="small" ref="tradeDialog" />
  </div>
</template>

<script>
import { PublicKey } from "@solana/web3.js";
import {
  getAssetValue,
  getAssetValueSY,
  APYCalculate2,
  PNLCalculate,
} from "@/assets/js/SYcalculate";
import store from "../store";
import SapDialog from "../components/sapDialog.vue";
import VestDialog from "../components/vestDialog.vue";
import TradeDialog from "../components/tradeDialog.vue";
import {
  getTransactionList,
  SY_LEN,
  serverOwner,
  sapProgramId,
  syProgramId,
} from "../assets/js/sap/sap";
import {
  createTokenAccount,
  getTokenAccountMaxAmount,
} from "../assets/js/getBalance";
import { getUserTotalStaking } from "../assets/js/stakingv2/staking";
import { rpcUrl } from "../assets/js/index";
import { TOKENS, SYPMINT } from "../assets/js/token";
import { getUserTestAuthority } from "../assets/js/sap/testCard";
import moment from "moment";
import { sleep } from "../assets/js/vesting/utils";

export default {
  name: "Investment",
  data() {
    return {
      range: "day",
      nowValue: 0,
      echartDate: [],
      echartShowData: [],
      indvDate: [],
      indvShowData: [],
      stakingYield: 0,
      tableData: [
        {
          Name: "Sypool-Quant",
          // Performance: "Sypool",
          Shares: "0",
          Value: "$",
          APY: "+0%",
        },
      ],
      stakedValue: 0,
      stakingAmount: 0,
      performanceTable: [],
      mintTypes: [
        {
          name: "Sypool-Quant",
          pubkey: "3XAEwQ7LchTS4CEs8VBJbwneByesWAR85CXmcncTPvdU",
          performance: "Sypool",
        },
      ],
      memberKey: "",
      memberPDA: "",
      SYPValue: 0.05,
      products: [],
      chartMin: 0,
      chartMax: 1000,
      chartIndvMin: 0,
      chartIndvMax: 1000,
      stakingOriginal: 100000 * 0.05,
      date: "",
      tabs: ["MINT", "BURN", "TRADE", "FARM", "STAKING", "CLAIM"],
      chartColor: ["#036d5f", "#490f45", "#7b726c", "#036d5f", "#490f45"],
      curActType: "",
      startInvestment: 100000,
      totalFundsValue: 0,
      myFundsLoading: true,
      sypBalance: 0,
      usdcBalance: 0,
      totalPNL: 0,
      totalPNLPercent: 0,
      walletKey: "",
      fundList: [],
      transactionHistoryList: [],
      mintbiger: false,
      burnbiger: false,
      tradebiger: false,
      userAuthority: 0, //need change to 0;
      myChart: "",
      myChart1: "",
      chart1Flag: true,
      loading: false,
    };
  },
  async mounted() {
    this.date = new Date().toDateString();
    this.owner = new PublicKey(serverOwner);
    this.sapProgramId = new PublicKey(sapProgramId);
    this.syProgramId = new PublicKey(syProgramId);
    this.SYPMINT = new PublicKey(SYPMINT);
    // this.stakingProgramID = new PublicKey(stakingProgramID);
    this.getData();
  },
  created() {
    // console.log(this.$store.state.walletConnectInfo);
  },
  methods: {
    async getData() {
      if (this.$store.state.walletConnectInfo) {
        this.loading = true;
        this.connection = this.$store.state.walletConnectInfo.connection;
        this.walletKey =
          this.$store.state.walletConnectInfo.publicKey.toBase58();
        this.getUserAuthority();
        this.getStakingData();
        this.getBalanceData();
        this.getMyPerformance();
        await this.getMyFunds();
        this.getTransactionHistory();
        this.calculatePNL2();
        // this.getIndvPerformance();
        this.loading = false;
      } else {
        console.error("hasn`t connect to wallet");
        this.$router.push("/");
      }
    },
    gotoSypool(row, event, column) {
      this.$router.push("/sypool?pubkey=" + row.pubkey + "&mint=" + row.mint);
    },
    async goClaim(ind) {
      // find my
      this.rLoading = this.openLoading();
      this.publicKey = this.$store.state.walletConnectInfo.publicKey;
      this.connection = this.$store.state.walletConnectInfo.connection;
      let mint = TOKENS[ind].pubkey;
      let acct = await this.connection.getTokenAccountsByOwner(this.publicKey, {
        mint: new PublicKey(mint),
      });
      if (acct.value.length > 0) {
        for (var j = 0; j < acct.value.length; j++) {
          let res = await this.connection.getTokenAccountBalance(
            acct.value[j].pubkey
          );
          if (res.value.uiAmount > 0) {
            this.$notify({
              title: "Error",
              message: "You have claimed.",
              type: "error",
            });
            this.rLoading.close();
            return;
          }
        }
      }
      let acc = await this.connection.getTokenAccountsByOwner(this.publicKey, {
        mint: new PublicKey(TOKENS[ind].pubkey),
      });
      this.rLoading.close();
      if (acc.value.length == 0) {
        this.$alert(
          "Sypool.io will help you create a token account first. Please authorize and wait a few seconds.",
          "Alert",
          {
            confirmButtonText: "Ok",
            confirmButtonClass: "alert_btn_ok",
          }
        );
        this.rLoading = this.openLoading();
        let res = await createTokenAccount(
          this.connection,
          this.$store.state.walletConnectInfo.wallet,
          new PublicKey(mint)
        );
        console.log("create token account", res);
        // let result = await this.$createMintTokenAccount(
        //   this.$store.state.walletConnectInfo.connection,
        //   // this.wallet,
        //   this.$store.state.walletConnectInfo.wallet,
        //   new PublicKey(TOKENS[ind].pubkey)
        // );
        while (acc.value.length == 0) {
          await sleep(3000);
          acc = await this.connection.getTokenAccountsByOwner(this.publicKey, {
            mint: new PublicKey(TOKENS[ind].pubkey),
          });
        }
        this.$notify({
          title: "Success",
          message: "Account created. Processing token claim now.",
          type: "success",
        });
        this.rLoading.close();
      }
      // has none
      this.rLoading = this.openLoading();

      let result = await this.$axios.post("/claimTokens", {
        pubkey: this.publicKey.toBase58(),
        mint: TOKENS[ind].pubkey,
      });
      if (result.data.code == 1) {
        this.$notify({
          title: "Success",
          message: "Claim succeeded",
          type: "success",
        });
      } else {
        this.$notify({
          title: "Error",
          message: result.data.msg,
          type: "error",
        });
      }
      this.rLoading.close();
    },

    async getMyFunds() {
      let result = await this.$axios.post("/getFundList", {
        net: 0,
      });
      let tempData = [];
      let products = result.data.data;
      let tempFundList = [];
      let pubkeyList = products.map((e) => {
        return e.pubkey;
      });
      let totalInvestment = 0;
      for (let i = 0; i < products.length; i++) {
        if (products[i].type == 0) {
          // check if user has product account
          let wallet = this.$store.state.walletConnectInfo;
          let res1 = await getTokenAccountMaxAmount(
            this.connection,
            wallet,
            new PublicKey(products[i].mint[0])
          );
          if (res1.code === 1) {
            if (res1.data.amount > 0) {
              let newTemp = {
                whiteList: products[i].whiteList,
                vaultList: products[i].vaultList,
                tokenList: products[i].tokenList,
                pubkey: products[i].pubkey,
                owner: products[i].owner,
                net: parseInt(products[i].net),
                name: products[i].name,
                initialized_date: products[i].initialized_date,
                init_value: parseFloat(products[i].init_value),
                init_price: parseFloat(products[i].init_price),
                mint: products[i].mint[0],
                type: parseInt(products[i].type),
                timelock: parseInt(products[i].timelock),
                shares: res1.data.amount || 0.0,
              };
              tempData.push(newTemp);
            }
          }
          // get fund
          tempFundList.push({
            name: products[i].name,
            mint: products[i].mint[0],
            pubkey: products[i].pubkey,
            type: 0,
          });
        } else if (products[i].type == 1) {
          for (let j = 1; j < 1 + SY_LEN; j++) {
            // get pre mint asset
            {
              let sapName = products[i].name + "-R" + j.toString() + " Pre";
              let wallet = this.$store.state.walletConnectInfo.wallet;
              let sapPreMintKey = products[i].mint[j + 1];
              let userPreSap;
              {
                let res = await getTokenAccountMaxAmount(
                  this.connection,
                  wallet,
                  sapPreMintKey
                );
                if (res.code == 1) {
                  userPreSap = res.data;
                }
              }
              if (userPreSap) {
                if (userPreSap.amount > 0) {
                  let newTemp = {
                    whiteList: products[i].whiteList,
                    vaultList: products[i].vaultList,
                    tokenList: products[i].tokenList,
                    pubkey: products[i].pubkey,
                    owner: products[i].owner,
                    net: parseInt(products[i].net),
                    name: sapName,
                    initialized_date: products[i].initialized_date,
                    init_value: parseFloat(products[i].init_value),
                    init_price: parseFloat(products[i].init_price),
                    mint: sapPreMintKey,
                    type: parseInt(products[i].type),
                    timelock: parseInt(products[i].timelock),
                    shares: userPreSap.amount,
                    ltype: j,
                    pre: true,
                  };
                  tempData.push(newTemp);
                }
              }
              // get fund
              tempFundList.push({
                name: sapName,
                mint: sapPreMintKey,
                pubkey: products[i].pubkey,
                type: 1,
                ltype: j,
              });
            }
            // get sap asset
            let wallet = this.$store.state.walletConnectInfo;
            let res1 = await getTokenAccountMaxAmount(
              this.connection,
              wallet,
              new PublicKey(products[i].mint[j - 1])
            );
            if (res1.code === 1) {
              if (res1.data.amount > 0) {
                let newTemp = {
                  whiteList: products[i].whiteList,
                  vaultList: products[i].vaultList,
                  tokenList: products[i].tokenList,
                  pubkey: products[i].pubkey,
                  owner: products[i].owner,
                  net: parseInt(products[i].net),
                  name: products[i].name + "-R" + j,
                  initialized_date: products[i].initialized_date,
                  init_value: parseFloat(products[i].init_value),
                  init_price: parseFloat(products[i].init_price),
                  mint: products[i].mint[j - 1],
                  type: parseInt(products[i].type),
                  timelock: parseInt(products[i].timelock),
                  shares: res1.data.amount || 0.0,
                  ltype: j,
                  pre: false,
                };
                tempData.push(newTemp);
              }
            }
            // get fund
            tempFundList.push({
              name: products[i].name + "-R" + j.toString(),
              mint: products[i].mint[j - 1],
              pubkey: products[i].pubkey,
              type: 1,
              ltype: j,
            });
          }
        }
      }
      this.fundList = tempFundList;
      let newTableData = [];
      for (let i = 0; i < tempData.length; i++) {
        let temp = {
          name: tempData[i].name,
          shares: tempData[i].shares.toFixed(2),
          value: "$0",
          APY: "0%",
          pnl: "$0",
          pubkey: tempData[i].pubkey,
          mint: tempData[i].mint,
        };
        newTableData.push(temp);
      }
      this.products = tempData;
      this.performanceTable = newTableData;
      for (let i = 0; i < tempData.length; i++) {
        let index = pubkeyList.indexOf(tempData[i].pubkey);
        let thisProduct = products[index];
        if (thisProduct.enableWoo) {
          let res = await this.$axios.get("/getFundWooAsset", {
            params: {
              publicKey: thisProduct.pubkey,
            },
          });
          if (res.data.code == 1) {
            thisProduct.wooAsset = res.data.data;
          } else {
            console.warn(res);
          }
        }
        if (tempData[i].type == 0) {
          let [maxA, percent, apy, cap, supply, onePrice] = await getAssetValue(
            this.connection,
            thisProduct
          );
          tempData[i].price = onePrice;
          tempData[i].value = tempData[i].price * tempData[i].shares;
          totalInvestment += tempData[i].value;
          let costPrice = await this.$axios.post("/getMySapCost", {
            user: this.$store.state.walletConnectInfo.publicKey.toBase58(),
            mint: tempData[i].mint,
          });
          if (costPrice.data.data != undefined) {
            tempData[i].costPrice = parseFloat(costPrice.data.data.price);
            tempData[i].startDate = costPrice.data.data.addTime;
          }
        } else if (tempData[i].type == 1) {
          let mints = thisProduct.mint;
          //maxA,percent,apy,cap,supply,onePrice
          let tempInd = mints.indexOf(tempData[i].mint);
          if (tempInd != -1) {
            let price = 0.0;
            if (!tempData[i].pre) {
              let ress = await getAssetValueSY(
                this.connection,
                rpcUrl,
                thisProduct
              );
              let [maxA, percent, apy, apy30d, apy1d, cap, supply, onePrice] =
                ress[tempInd];
              price = onePrice;
            } else {
              price = tempData[i].init_price;
            }
            tempData[i].price = price;
            tempData[i].value = tempData[i].price * tempData[i].shares;
            totalInvestment += tempData[i].value;
            let costPrice = await this.$axios.post("/getMySapCost", {
              user: this.$store.state.walletConnectInfo.publicKey.toBase58(),
              mint: tempData[i].mint,
            });
            if (costPrice.data.data != undefined) {
              tempData[i].costPrice = parseFloat(costPrice.data.data.price);
              tempData[i].startDate = costPrice.data.data.addTime;
            }
          }
        }
      }
      // calculate by data from chain
      // this.totalFundsValue = totalInvestment;
      newTableData = [];
      for (let i = 0; i < tempData.length; i++) {
        let temp = {
          name: "",
          shares: 0,
          value: "$0",
          costPrice: "$0",
          curPrice: "$0",
          pnl: "$0",
          APY: "0%",
        };
        temp.name = tempData[i].name;
        temp.shares = tempData[i].shares;
        let value = tempData[i].value;
        temp.value = "$" + value.toFixed(2);
        let curPrice = tempData[i].price;
        temp.curPrice = "$" + curPrice.toFixed(2);
        temp.pubkey = tempData[i].pubkey;
        temp.mint = tempData[i].mint;
        if (tempData[i].costPrice != undefined) {
          let costPrice = tempData[i].costPrice;
          temp.costPrice = "$" + costPrice.toFixed(2);
          let pnl = PNLCalculate(costPrice, curPrice, temp.shares);
          temp.pnl = "$" + pnl.toFixed(2);
          temp.APY = (APYCalculate2(value, pnl) * 100).toFixed(2) + "%";
        }
        newTableData.push(temp);
      }
      this.performanceTable = newTableData;
      this.products = tempData;
      this.myFundsLoading = false;
    },
    async getMyPerformance() {
      let results = await this.$axios.post("/getMyPerformance", {
        user: this.walletKey,
      });
      let data = results.data.data;
      let dates = data.map((e) => {
        return this.formatDate2(new Date(e.date));
      });
      let values = data.map((e) => {
        return e.value;
      });
      this.totalFundsValue = values[values.length - 1];
      this.echartShowData = values;
      this.echartDate = dates;
      this.echartsInit();
      this.indvDate = dates;
      let sapValueList = data.map((e) => {
        return e.sapValue;
      });
      let indvDataList = {};
      for (let i = 0; i < sapValueList.length; i++) {
        let sapValue = sapValueList[i];
        for (let j = 0; j < sapValue.length; j++) {
          let sapTemp = sapValue[j];
          let key = sapTemp.name;
          let value = 0;
          if (sapTemp.price) {
            value = sapTemp.price * sapTemp.share;
          }
          if (indvDataList[key]) {
            indvDataList[key].data.push(value);
          } else {
            let fillData = [];
            fillData.fill(0, 0, i);
            indvDataList[key] = {
              name: key,
              type: "line",
              data: [...fillData, value],
              itemStyle: {
                opacity: 0,
              },
            };
          }
        }
      }
      let indvData = [];
      for (let key in indvDataList) {
        if (indvDataList[key].data.length >= sapValueList.length) {
          indvData.push(indvDataList[key]);
        }
      }
      this.indvShowData = indvData;
    },

    async getIndvPerformance() {
      // get data from server
      let results = await this.$axios.post("/getIndvPerformance", {
        user: this.walletKey,
      });
      let data = results.data.data;
      // get date
      let dateList = data.map((e) => {
        return e.date;
      });
      let dateStringList = dateList.map((e) => {
        return moment(e).format("MM-DD HH:mm");
      });

      // generate product
      let productList = this.products.map((e) => {
        return e.name;
      });
      let indvDataObj = {};
      for (let i = 0; i < productList.length; i++) {
        let key = productList[i];
        indvDataObj[key] = {
          name: key,
          type: "line",
          smooth: true,
          showSymbol: false,
          data: [],
        };
      }

      // handle sap value
      for (let i = 0; i < dateList.length; i++) {
        let dateTemp = dateList[i];
        let dataTemp = data.find((e) => {
          return e.date == dateTemp;
        });
        let sapTemp = dataTemp.sapValue;
        // console.log('sap temp', i, sapTemp)
        for (let j = 0; j < productList.length; j++) {
          let sapNameTemp = productList[j];
          let sapDataTemp = sapTemp.filter((e) => {
            return sapNameTemp == e.name;
          });
          if (sapDataTemp.length > 0) {
            indvDataObj[sapNameTemp].data.push(sapDataTemp[0].price);
          } else {
            indvDataObj[sapNameTemp].data.push(NaN);
          }
        }
      }

      // output
      this.indvDate = dateStringList;
      let indvData = [];
      for (let key in indvDataObj) {
        indvData.push(indvDataObj[key]);
      }
      this.indvShowData = indvData;
    },

    async getTransactionHistory() {
      let res = await getTransactionList(this.connection, this.walletKey, "");
      if (res.length > 0) {
        this.transactionHistoryList = res.filter((e) => {
          return e.action > 0;
        });
        if (this.transactionHistoryList.length > 0) {
          let fundPubkeyList = this.fundList.map((e) => {
            return e.pubkey;
          });
          for (let i = 0; i < this.transactionHistoryList.length; i++) {
            let pubkey = this.transactionHistoryList[i].pubkey;
            let index = fundPubkeyList.indexOf(pubkey);
            if (index != -1) {
              this.$set(
                this.transactionHistoryList[i],
                "sapName",
                this.fundList[index].name
              );
            }
          }
          this.$axios.post("/saveUserTransactionHistory", {
            user: this.walletKey,
            data: this.transactionHistoryList,
          });
        }
      }
    },

    // calculate pnl by 105000
    calculatePNL() {
      let initDate = new Date(2021, 12, 3, 0, 0);
      let period = Math.max(
        1,
        Math.floor(
          (new Date().getTime() - initDate.getTime()) / 3600000 / 30 / 24
        )
      );
      let baseValue = 100000 + 0.05 * 100000;
      this.totalPNL = this.totalInvestment - baseValue;
      this.totalPNLPercent =
        (1 + this.totalPNL / baseValue / period) ** (1 / period) - 1;
    },

    // calculate pnl by user value, can`t calculate first day
    async calculatePNL2() {
      var userValueDaily = await this.getUserValue2();
      var pnlDaily = [];
      for (let i = 0; i < userValueDaily.length; i++) {
        let todayValue;
        if (i == 0) {
          todayValue = { id: "first data", comp: [], sapValue: 0 };
        } else {
          todayValue = userValueDaily[i - 1];
        }
        let todayEndValue = userValueDaily[i];
        let change = this.calculateChange(todayValue.comp, todayEndValue.comp);
        let todayPNL = todayEndValue.sapValue - todayValue.sapValue - change;
        pnlDaily.push({
          change: change,
          todayValue: todayValue.sapValue,
          todayEndValue: todayEndValue.sapValue,
          todayPNL,
          date: todayEndValue.date,
        });
      }
      console.log("pnl daily", pnlDaily);
      let totalPNL = 0;
      let totalCost = 0;
      pnlDaily.forEach((e, index) => {
        totalPNL += e.todayPNL;
        if (index == 0) {
          totalCost += e.change;
        } else {
          totalCost += e.change / (pnlDaily.length - 1);
        }
      });
      console.log("total pnl", totalPNL, "total cost", totalCost);
      this.totalPNL = totalPNL;
      let totalPNLPercent = totalPNL / totalCost;
      if (totalCost == 0) {
        totalPNLPercent = 0.0;
      }
      this.totalPNLPercent = totalPNLPercent;
    },

    async getUserValue2() {
      let results = await this.$axios.post("/getUserValue", {
        user: this.$store.state.walletConnectInfo.publicKey.toBase58(),
      });
      let data = results.data.data;
      var userValue = [];
      let dateIndex = 0;
      let dateList = data.map((e) => {
        let date = new Date(e.date);
        date.setUTCHours(0);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
        return date.getTime();
      });
      let startDateTime = Math.min(...dateList);
      for (let i = 0; i < data.length; i++) {
        let tempDate = new Date(data[i].date);
        let tempNow = new Date(
          startDateTime + (1000 * 3600 * 24 * dateIndex - 1000 * 10)
        );
        if (tempDate.getTime() - tempNow.getTime() > 0) {
          tempDate.setUTCHours(0);
          tempDate.setUTCMinutes(0);
          tempDate.setUTCSeconds(0);
          tempDate.setUTCMilliseconds(0);
          data[i].date = tempDate;
          userValue.push(data[i]);
          dateIndex++;
        }
      }
      userValue.forEach((e) => {
        let sapValue = 0;
        if (e.comp.length > 0) {
          for (let i = 0; i < e.comp.length; i++) {
            let name = e.comp[i].name;
            if (name != "USDC" && name != "SYP" && name != "staking")
              sapValue += e.comp[i].value;
          }
        }
        e.sapValue = sapValue;
      });
      const userSapValueHas = userValue.map((e) => {
        return e.sapValue > 0 ? 1 : 0;
      });
      const startIndex = userSapValueHas.indexOf(1);
      if (startIndex != -1) {
        userValue.splice(0, startIndex);
      } else {
        userValue = [];
      }
      let todaySapValue = {
        _id: "today",
        comp: [],
        date: new Date(),
        net: 0,
        sapValue: 0,
        user: "",
        value: this.totalInvestment,
      };
      let tempSapValue = this.products;
      for (let i = 0; i < tempSapValue.length; i++) {
        let tempSap = tempSapValue[i];
        let tempSap2 = {
          mint: tempSap.mint,
          name: tempSap.name,
          price: tempSap.price,
          pubkey: tempSap.pubkey,
          share: tempSap.shares,
          value: tempSap.value,
        };
        todaySapValue.comp.push(tempSap2);
        todaySapValue.sapValue += tempSap2.value;
      }
      userValue.push(todaySapValue);
      console.log("user value daily", userValue);
      return userValue;
    },

    // caluclate pnl by user value and user transaction
    async calculatePNL3() {
      var userValueDaily = await this.getUserValue3();
      let userTransactionDaily = await this.getUserTransaction3();
      var pnlDaily = [];
      for (let i = 0; i < userValueDaily.length - 1; i++) {
        let todayValue = userValueDaily[i];
        let todayEndValue = userValueDaily[i + 1];
        let todayTransaction = userTransactionDaily.filter((e) => {
          return e.date.getTime() == todayEndValue.date.getTime();
        });
        let change = 0;
        if (todayTransaction.length != 0) {
          change = todayTransaction[0].change;
        }
        let todayPNL = todayEndValue.sapValue - todayValue.sapValue - change;
        pnlDaily.push({
          change: change,
          todayValue: todayValue.sapValue,
          todayEndValue: todayEndValue.sapValue,
          todayPNL,
          date: todayEndValue.date,
        });
      }
      console.log("pnl daily", pnlDaily);
      let totalPNL = 0;
      let totalCost = 0;
      pnlDaily.forEach((e, index) => {
        totalPNL += e.todayPNL;
        if (index == 0) {
          totalCost += e.change;
        } else {
          totalCost += e.change / (pnlDaily.length - 1);
        }
      });
      console.log("total pnl", totalPNL, "total cost", totalCost);
      this.totalPNL = totalPNL;
      let totalPNLPercent = totalPNL / totalCost;
      this.totalPNLPercent = totalPNLPercent;
    },

    async getUserValue3() {
      // get server user value
      let results = await this.$axios.post("/getUserValue", {
        user: this.$store.state.walletConnectInfo.publicKey.toBase58(),
      });
      let data = results.data.data;
      var userValue = [];
      var dateIndex = 0;
      let dateList = data.map((e) => {
        let date = new Date(e.date);
        date.setUTCHours(0);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
        return date.getTime();
      });
      let startDateTime = Math.min(...dateList);
      for (let i = 0; i < data.length; i++) {
        let tempDate = new Date(data[i].date);
        let tempNow = new Date(
          startDateTime + (1000 * 3600 * 24 * dateIndex - 1000 * 10)
        );
        if (tempDate.getTime() - tempNow.getTime() > 0) {
          tempDate.setUTCHours(0);
          tempDate.setUTCMinutes(0);
          tempDate.setUTCSeconds(0);
          tempDate.setUTCMilliseconds(0);
          data[i].date = tempDate;
          userValue.push(data[i]);
          dateIndex++;
        }
      }
      userValue.forEach((e) => {
        let sapValue = 0;
        if (e.comp.length > 0) {
          for (let i = 0; i < e.comp.length; i++) {
            let name = e.comp[i].name;
            if (name != "USDC" && name != "SYP" && name != "staking")
              sapValue += e.comp[i].value;
          }
        }
        e.sapValue = sapValue;
      });
      const userSapValueHas = userValue.map((e) => {
        return e.sapValue > 0 ? 1 : 0;
      });
      const startIndex = userSapValueHas.indexOf(1);
      if (startIndex != -1) {
        userValue.splice(0, startIndex);
      } else {
        userValue = [];
      }
      // get first user value
      let firstUserValue = {
        _id: "firstday",
        date: userValue[0].date,
        comp: [],
        net: userValue[0].net,
        sapValue: 0,
        user: userValue[0].user,
        value: 0,
      };
      userValue.unshift(firstUserValue);
      // get today user value
      let todaySapValue = {
        _id: "today",
        comp: [],
        date: new Date(),
        net: 0,
        sapValue: 0,
        user: "",
        value: this.totalInvestment,
      };
      let tempSapValue = this.products;
      for (let i = 0; i < tempSapValue.length; i++) {
        let tempSap = tempSapValue[i];
        let tempSap2 = {
          mint: tempSap.mint,
          name: tempSap.name,
          price: tempSap.price,
          pubkey: tempSap.pubkey,
          share: tempSap.shares,
          value: tempSap.value,
        };
        todaySapValue.comp.push(tempSap2);
        todaySapValue.sapValue += tempSap2.value;
      }
      userValue.push(todaySapValue);
      console.log("user value daily", userValue);
      return userValue;
    },

    async getUserTransaction3() {
      let results = await this.$axios.post("/getUserTransaction", {
        user: this.$store.state.walletConnectInfo.publicKey.toBase58(),
      });
      let data = results.data.data;
      console.log("user transaction", data);
      var userTransaction = [];
      let dateList = data.map((e) => {
        let date = new Date(e.date);
        date.setUTCHours(0);
        date.setUTCMinutes(0);
        date.setUTCSeconds(0);
        date.setUTCMilliseconds(0);
        return date.getTime();
      });
      dateList = this.unique(dateList);
      for (let i = 0; i < dateList.length; i++) {
        let tempNow = new Date(dateList[i]);
        let todayTransaction = data.filter((e) => {
          let tempDate = new Date(e.date);
          return (
            tempDate.getTime() - tempNow.getTime() >= 0 &&
            tempDate.getTime() - tempNow.getTime() < 1000 * 3600 * 24
          );
        });
        if (todayTransaction.length != 0) {
          let tempChange = 0;
          let tempTransactions = [];
          for (let j = 0; j < todayTransaction.length; j++) {
            let tempTransaction = todayTransaction[j];
            // 1 buy 2 burn
            if (tempTransaction.type == 1) {
              tempChange += tempTransaction.amount * tempTransaction.price;
            } else if (tempTransaction.type == 2) {
              tempChange -= tempTransaction.amount * tempTransaction.price;
            }
            tempTransactions.push(tempTransaction);
          }
          userTransaction.push({
            date: tempNow,
            change: tempChange,
            transaction: tempTransactions,
          });
        }
      }
      console.log("user transaction daily", userTransaction);
      return userTransaction;
    },

    calculateChange(todayComp, todayEndComp) {
      let comp = {};
      for (let i = 0; i < todayComp.length; i++) {
        let sap = todayComp[i];
        if (sap.name != "USDC" && sap.name != "SYP" && sap.name != "staking") {
          if (!comp[sap.name]) {
            comp[sap.name] = { today: undefined, todayEnd: undefined };
          }
          comp[sap.name].today = sap;
        }
      }
      for (let i = 0; i < todayEndComp.length; i++) {
        let sap = todayEndComp[i];
        if (sap.name != "USDC" && sap.name != "SYP" && sap.name != "staking") {
          if (!comp[sap.name]) {
            comp[sap.name] = { today: undefined, todayEnd: undefined };
          }
          comp[sap.name].todayEnd = sap;
        }
      }
      var change = 0;
      for (let key in comp) {
        let todaySap = comp[key].today;
        let todayEndSap = comp[key].todayEnd;
        if (!todaySap) {
          todaySap = { share: 0, price: todayEndSap.price };
        }
        if (!todayEndSap) {
          todayEndSap = { share: 0 };
        }
        let tempChange = (todayEndSap.share - todaySap.share) * todaySap.price;
        change += tempChange;
      }
      return change;
    },

    async getPerformanceForEchart() {
      let res = await this.$axios.post("/getPerformanceForEchart", {
        range: this.range,
        mintTypes: this.mintTypes,
      });
      let date = [];
      let tempdata = res.data.sapMinPrice;
      let show = [];

      let tempshow = [];
      for (let i = 0; i < tempdata.length; i++) {
        for (let j = 0; j < tempdata[i].length; j++) {
          // console.log(tempdata[i],'tempdatatempdata')
          if (i == 0) {
            date.push(tempdata[i][j].date);
            tempshow.push(undefined);
          }
          for (let k = 0; k < this.transactionHistory.length; k++) {
            // console.log(tempdata[i][j].timestamp)
            // console.log(this.transactionHistory[k].blockTime)
            if (
              this.transactionHistory[k].blockTime <= tempdata[i][j].timestamp
            ) {
              tempshow[j] =
                this.transactionHistory[k].meta.postTokenBalances.uiTokenAmount
                  .uiAmount * tempdata[i][j].price;
            }
          }
        }

        // // for (let j = 0; j < tempdata[i].length; j++) {
        //   for (let k = 0; k < this.transactionHistory.length; k++) {
        //     // console.log(tempdata[i][j].timestamp, "timestamp");
        //     // console.log(this.transactionHistory[k].blockTime);
        //     if(this.transactionHistory[k].blockTime<=tempdata[i][j].timestamp){
        //       // console.log(this.transactionHistory[k].meta.postTokenBalances.uiTokenAmount.amount)
        //       tempshow.push(this.transactionHistory[k].meta.postTokenBalances.uiTokenAmount.amount)
        //       this.transactionHistory.splice(k,1)
        //     }else{
        //       tempshow.push('')
        //     }
        //   }
        // // }

        show.push(tempshow);
      }
      this.echartDate = date;
      this.echartShowData = tempshow;
      // if(this.range =='minute'){

      // }else if(this.range=='day'){

      // }else if(this.range =='week'){

      // }else if(this.range == "month"){

      // }
    },

    async getStakingYield(sypAcc) {
      // get tran
      let transSignatures =
        await this.connection.getConfirmedSignaturesForAddress2(sypAcc);
      let stakingYield = 0;
      for (var i = 0; i < transSignatures.length; i++) {
        const signature = transSignatures[i].signature;
        const confirmedTransactions =
          await this.$store.state.walletConnectInfo.connection.getConfirmedTransaction(
            signature
          );

        if (
          confirmedTransactions.transaction.instructions[0].programId.toBase58() !=
          stakingProgramID
        ) {
        }
        if (
          confirmedTransactions.transaction.instructions[0].programId.toBase58() ==
          stakingProgramID
        ) {
          let trr = confirmedTransactions.transaction.instructions[0];
          let action = trr.data[0];
          let preBalance = confirmedTransactions.meta.preTokenBalances;
          let postBalances = confirmedTransactions.meta.postTokenBalances;
          let preAmount = parseFloat(preBalance[0].uiTokenAmount.uiAmount);
          let postAmount = parseFloat(postBalances[0].uiTokenAmount.uiAmount);
          if (action == 10) {
            let amount = parseFloat(Math.abs(postAmount - preAmount));
            stakingYield += amount;
          }
        }
      }
      this.stakingYield = parseFloat(
        parseFloat(this.stakingYield) +
          parseFloat(stakingYield * 0.05).toFixed(3)
      );
      console.log("staking yield", this.stakingYield);
    },

    async getBalanceData() {
      let sypToken = TOKENS.filter((e) => {
        return e.token == "SYP";
      })[0];
      let sypMint = new PublicKey(sypToken.pubkey);
      console.log("syp mint", sypMint.toBase58());
      let sypacc =
        await this.$store.state.walletConnectInfo.connection.getTokenAccountsByOwner(
          this.$store.state.walletConnectInfo.publicKey,
          { mint: this.SYPMINT }
        );
      console.log("syp acc", sypacc);
      if (sypacc.value.length > 0) {
        let res =
          await this.$store.state.walletConnectInfo.connection.getTokenAccountBalance(
            sypacc.value[0].pubkey
          );
        this.sypBalance = res.value.uiAmount * sypToken.price;
      }

      let usdcToken = TOKENS.filter((e) => {
        return e.token == "USDC";
      })[0];
      let usdcMint = new PublicKey(usdcToken.pubkey);
      console.log("usdc mint", usdcMint.toBase58());
      let usdcacc =
        await this.$store.state.walletConnectInfo.connection.getTokenAccountsByOwner(
          this.$store.state.walletConnectInfo.publicKey,
          { mint: usdcMint }
        );
      console.log("usdc acc", usdcacc);
      if (usdcacc.value.length > 0) {
        let res =
          await this.$store.state.walletConnectInfo.connection.getTokenAccountBalance(
            usdcacc.value[0].pubkey
          );
        this.usdcBalance = res.value.uiAmount * usdcToken.price;
      }
    },

    openDialog(index) {
      store.commit("SET_DIALOG_ACT", this.tabs[index]);
    },

    openDialog2(index) {
      if (index == 0 || index == 1) {
        if (this.userAuthority == 0) {
          return;
        }
      }
      if (index === 0) {
        this.mintbiger = true;
      } else if (index === 1) {
        this.burnbiger = true;
      }
      this.$refs.sapDialog.activeName = this.tabs[index];
      this.$refs.sapDialog.dialogVisible = true;
    },

    small(p) {
      this[p] = false;
      console.log("&&&", p, this[p]);
    },

    actionFarm() {
      this.$router.push({ name: "Staking", params: { tab: "farming" } });
    },

    actionStake() {
      this.$router.push({ name: "Staking", params: { tab: "staking" } });
    },

    actionVest() {
      this.$refs.vestDialog.show = true;
    },
    actionTrade() {
      this.$refs.tradeDialog.dialogVisible = true;
    },

    echartsInit() {
      // 找到容器
      const myChart = this.$echarts.init(document.getElementById("myChart"));
      // 开始渲染
      myChart.setOption({
        grid: {
          bottom: 30,
          left: 80,
          top: 40,
          right: 50,
        },
        tooltip: {
          backgroundColor: "#1b152b",
          borderWidth: "0",
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          // data: ["2021", "2022", "2023", "2024", "2025", "2026", "2027"],
          data: this.echartDate,
          name: "Date",
          boundaryGap: false,
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "value",
          name: "value",
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              color: "#25242b",
            },
          },
          min: function (value) {
            return Math.round(value.min) - 5;
          },
          max: function (value) {
            return Math.round(value.max) + 5;
          },
        },
        series: [
          {
            // data: [150, 230, 224, 218, 135, 147, 260],
            data: this.echartShowData,
            type: "line",
            itemStyle: {
              opacity: 0,
            },
          },
        ],
      });
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    echartsIndvInit() {
      // 找到容器
      const myChart1 = this.$echarts.init(document.getElementById("indChart"));
      // 开始渲染
      myChart1.setOption({
        grid: {
          bottom: 30,
          left: 80,
          top: 40,
          right: 50,
        },
        tooltip: {
          backgroundColor: "#1b152b",
          borderWidth: "0",
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: this.indvDate,
          name: "Date",
          boundaryGap: false,
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "value",
          name: "value",
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              color: "#25242b",
            },
          },
          min: function (value) {
            return Math.round(value.min) - 5;
          },
          max: function (value) {
            return Math.round(value.max) + 5;
          },
        },
        series: this.indvShowData,
      });
      this.myChart1 = myChart1;
      window.onresize = myChart1.resize;
    },

    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    formatDate(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo * 1000);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) +
        ":" +
        this.add0(s)
      );
    },
    formatDate2(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        // y +
        // "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) //+
        // ":" +
        // this.add0(s)
      );
    },
    sd(numbers) {
      if (numbers.length > 0) {
        const mean = numbers.reduce((acc, n) => acc + n) / numbers.length;
        return Math.sqrt(
          numbers.reduce((acc, n) => (n - mean) ** 2) / numbers.length
        );
      }
    },
    unique(arr) {
      return arr.filter(function (item, index, arr) {
        //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index;
      });
    },
    async getUserAuthority() {
      try {
        let walletAcc = this.$store.state.walletConnectInfo.publicKey;
        let res = await this.$axios.post("/getUserAuthority", {
          publicKey: walletAcc.toBase58(),
        });
        if (res.data.code == 1) {
          let user = res.data.data;
          if (user.authority) {
            // 1 test 0 nomal
            this.userAuthority = user.authority;
          } else {
            this.userAuthority = 0;
          }
        } else {
          console.error("get user authority error", res.data);
        }
      } catch (e) {
        console.error("get user authority error", e);
      }
    },
    async getUserAuthority2() {
      try {
        // for debug
        // this.userAuthority = 1;
        // return;
        let wallet = this.$store.state.walletConnectInfo.wallet;
        let res = await getUserTestAuthority(this.connection, wallet);
        if (res.code == 1) {
          this.userAuthority = 1;
        } else {
          this.userAuthority = 0;
        }
      } catch (e) {
        console.error("get user authority error", e);
      }
    },
    async getStakingData() {
      try {
        let wallet = this.$store.state.walletConnectInfo.wallet;
        let amount = await getUserTotalStaking(this.connection, wallet);
        this.stakingAmount = amount;
      } catch (e) {
        console.error("get staking data error", e);
      }
    },
    onCopy() {
      // this.$message({ message: "Copy success", type: "success" });
      this.$notify({
        title: "Success",
        message: "Copy success.",
        type: "success",
      });
    },
    viewOnSolscan(path) {
      window.open("https://solscan.io/account/" + path, "_blank");
    },
    viewOnSolscanTx(path) {
      window.open("https://solscan.io/tx/" + path, "_blank");
    },
  },
  computed: {
    totalInvestment() {
      return this.totalFundsValue;
    },
  },
  components: {
    SapDialog,
    VestDialog,
    TradeDialog,
  },
};
</script>

<style lang="less" scoped>
.view {
  height: 100%;
  overflow: hidden auto;
  padding: 35px;
  background: #151515;
  border-radius: 15px;

  @media (max-width: 980px) {
    padding: 15px;
  }
}

.btn,
.btn-c {
  @media (max-width: 980px) {
    margin-top: 15px;
  }
}

.text1 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  span {
    margin-left: 5px;
  }
}

.amount {
  color: #48bafd;
}

.title3 {
  font-size: 24px;
  font-weight: bold;
}

.box1 {
  margin-top: 25px;
  padding: 20px;
  background-color: #1b222f;
  border-radius: 15px;
}
</style>
