<template>
  <el-dialog :visible.sync="show" :append-to-body="true">
    <template slot="title">
      <div class="title">Vesting</div>
    </template>
    <div class="table-box" v-if="vestList.length > 0">
      <!-- <div class="table-box" v-if="2>0"> -->
      <el-table :data="vestList" class="table">
        <el-table-column label="Token in">
          <template>
            <div class="asset-box">
              <asset-icon asset="SYP" class="asset-icon"></asset-icon>
              <span>SYP</span>
              <!-- <span>{{ scope.row.token }}</span> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Amount">
          <template slot-scope="scope">{{
            scope.row.amount.toLocaleString()
          }}</template>
        </el-table-column>
        <el-table-column label="Unlocks">
          <template slot-scope="scope">
            <span v-if="scope.row.unlock > 0">
              {{ scope.row.unlock }}
              <span v-if="scope.row.unlock == 1">Day</span>
              <span v-if="scope.row.unlock > 1">Days</span>
            </span>
            <span v-else>Unlocked</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <div class="left-day" v-if="scope.row.unlock > 0">Locked</div>
            <el-button
              class="btn btn-c"
              v-else
              @click="actionVest(scope.row)"
              v-loading="acting"
            >
              Withdraw
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="none" v-else>Looks like you have no vests</div>
  </el-dialog>
</template>

<script>
import { Connection, PublicKey, Transaction } from "@solana/web3.js";
import { getTokenAccounts, createTokenAccount } from "../assets/js/getBalance";
import AssetIcon from "./assetIcon.vue";
import { claimVesting } from "../assets/js/vesting2";
import { rpcConfig, rpcUrl } from "../assets/js";
import { sleep } from "../assets/js/vesting/utils";
const hourTime = 1000 * 3600;
const dayTime = 24 * hourTime;

export default {
  name: "VestingDialog",
  data() {
    return {
      connection: new Connection(rpcUrl, rpcConfig),
      walletKey: "",
      show: false,
      vestList: [],
      acting: false,
    };
  },
  methods: {
    async getVest() {
      let res = await this.$axios.post("/getUserVest", {
        user: this.walletKey,
      });
      if (res.data.code) {
        let vestList = res.data.data;
        let vestList2 = [];
        let nowTime = new Date().getTime();
        for (let i = 0; i < vestList.length; i++) {
          let vestTemp = vestList[i];
          if (vestTemp.claimed == 0 && vestTemp.amount > 0) {
            let unlockTime = new Date(vestTemp.unlockDate).getTime();
            let expireTime = new Date(vestTemp.expireDate).getTime();
            if (expireTime > nowTime) {
              vestList2.push({
                token: "SYP",
                amount: vestTemp.amount,
                unlock: Math.ceil((unlockTime - nowTime) / dayTime),
                id: vestTemp._id,
              });
            }
          }
        }
        this.vestList = vestList2;
      } else {
        console.error("get vest error", res.data.msg, res.data.error);
      }
    },
    async goVest(vest) {
      const sypMintKeyMain = "FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ";
      let wallet = this.$store.state.walletConnectInfo.wallet;
      let walletAcc = wallet.publicKey;
      let userSypAcc = await getTokenAccounts(
        this.connection,
        walletAcc,
        new PublicKey(sypMintKeyMain)
      );
      if (!userSypAcc) {
        this.$notify({
          title: "Warning",
          message: "You has no SYP account.",
          type: "warning",
        });
        let temp = await createTokenAccount(
          this.connection,
          wallet,
          sypMintKeyMain
        );
        if (temp.code == 1) {
          while (!userSypAcc) {
            await sleep(1000);
            userSypAcc = await getTokenAccounts(
              this.connection,
              walletAcc,
              new PublicKey(sypMintKeyMain)
            );
          }
          this.notifyWithSignature(
            "Create SYP account success. Please sign claim.",
            temp.signatrue
          );
        } else if (temp.code == -1) {
          this.$notify({
            title: "Error",
            message: "Transaction canceled.",
            type: "error",
          });
          return;
        } else {
          this.$notify({
            title: "Error",
            message: "Create SYP account error.",
            type: "error",
          });
          return;
        }
      }
      const {
        data: { data },
      } = await this.$axios.post("/claimVest1a", {
        _id: vest.id,
        dest: userSypAcc.toBase58(),
        blockhash: await this.connection.getLatestBlockhash(),
      });
      let tx = Transaction.from(Buffer.from(data, "base64"));
      tx = await wallet.signTransaction(tx);
      console.log("tx", tx);
      const signature = await this.connection.sendRawTransaction(
        tx.serialize(),
        { preflightCommitment: "confirmed", maxRetries: 15 }
      );
      if (signature) {
        this.$axios.post("/claimVest1b", {
          _id: vest.id,
          signature,
        });
        this.notifyWithSignature("Claim Reward Success", signature);
      } else {
        this.$notify({
          title: "Error",
          message: "Claim Reward Fail",
          type: "error",
        });
      }
    },
    async actionVest(vest) {
      if (this.acting == false) {
        this.acting = true;
        try {
          await this.goVest(vest);
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Error",
            message: "Transaction error.",
            type: "error",
          });
        }
        this.acting = false;
        this.getVest();
      }
    },
    async notifyWithSignature(msg, signature) {
      // let temp = await getSignatureStatus(this.connection, signature);
      const h = this.$createElement;
      this.$notify({
        title: "Success",
        dangerouslyUseHTMLString: true,
        message: h("div", {}, [
          h("p", {}, msg),
          h("p", {}, [
            h(
              "a",
              {
                attrs: {
                  href: "https://explorer.solana.com/tx/" + signature,
                  target: "_blank",
                },
              },
              "view on explore"
            ),
          ]),
        ]),
        type: "success",
      });
    },
  },
  mounted() {
    if (this.$store.state.walletConnectInfo) {
      //   this.connection = this.$store.state.walletConnectInfo.connection;
      this.walletKey = this.$store.state.walletConnectInfo.publicKey.toBase58();
      this.getVest();
    }
  },
  computed: {},
  components: {
    AssetIcon,
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 22px;
  color: white;
  text-transform: uppercase;
}
.btn {
  font-size: 16px;
  padding: 12px 0;
  width: 100%;
  text-align: center;
  text-transform: none !important;
}
.table {
  .asset-box {
    display: flex;
    align-items: center;
  }
  .asset-icon {
    margin-right: 10px;
  }
}
.el-table {
  font-size: 20px;
  color: white;
}
.left-day {
  font-size: 19px;
  padding: 12px 0;
  width: 100%;
  background-color: #595959;
  color: white;
  text-align: center;
  cursor: not-allowed;
}
.none {
  font-size: 20px;
  color: white;
  text-align: center;
}
@media (max-width: 980px) {
  .title {
    font-size: 16px;
    font-weight: normal;
  }
  .none {
    font-size: 14px;
    font-weight: normal;
  }
  .btn {
    font-size: 14px;
    padding: 10px 0;
  }
  .table {
    .asset-icon {
      margin-right: 10px;
    }
  }
  .el-table {
    font-size: 14px;
  }
  .left-day {
    font-size: 14px;
    padding: 10px 0;
  }
}
</style>
